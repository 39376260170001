import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BasicDialogComponent } from '@ih/basic-dialog';
import { InfoPanelComponent } from '@ih/info-panel';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

export interface ConfirmPasswordDialogOptions {
  email: string;
}

@Component({
  selector: 'ih-confirm-password-dialog',
  templateUrl: './confirm-password-dialog.component.html',
  styleUrls: ['./confirm-password-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,

    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    ReactiveFormsModule,
    BasicDialogComponent,
    InfoPanelComponent
  ]
})
export class ConfirmPasswordDialogComponent implements OnInit {
  @HostBinding('class.ih-confirm-password-dialog') hostClass = true;

  currentPasswordEl!: HTMLInputElement;

  passwordForm = new UntypedFormGroup({
    currentPassword: new UntypedFormControl('', Validators.required, this.passwordCheckAsync.bind(this))
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPasswordDialogOptions,
    private mdDialogRef: MatDialogRef<ConfirmPasswordDialogComponent>,
    private elementRef: ElementRef,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.currentPasswordEl = this.elementRef.nativeElement.querySelector('#currentPassword');
  }

  showHidePassword(passwordEl: HTMLInputElement): void {
    passwordEl.type = passwordEl.type === 'password' ? 'text' : 'password';
  }

  passwordCheckAsync(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value) {
      return of(control.value).pipe(
        delay(500),
        switchMap((password) => {
          return this.http
            .post('/api/account/password/check', JSON.stringify(password), {
              headers: {
                'content-type': 'application/json'
              }
            })
            .pipe(
              map(() => null),
              catchError(() =>
                of({
                  invalidPassword: true
                })
              )
            );
        })
      );
    }
    return of(null);
  }

  cancel(): void {
    this.mdDialogRef.close();
  }

  submit(): void {
    if (this.passwordForm.valid) {
      this.mdDialogRef.close(this.passwordForm.get('currentPassword')!.value);
    }
  }
}
