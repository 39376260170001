<ih-basic-dialog>
  <ng-container ih-header>Confirm password</ng-container>
  <ng-container ih-content>
    <form id="passwordForm" [formGroup]="passwordForm" (ngSubmit)="submit()">
      <div class="fx-layout-column">
        <ih-info-panel [dismissable]="false">
          A verification email will be sent to your new email address <strong>{{ data.email }}</strong
          ><br />
          Click the verify link in the email to complete the email change process
        </ih-info-panel>
        <!-- <h4>Confirm your password</h4> -->
        <mat-form-field appearance="fill" class="current-password-field">
          <mat-label>Current password</mat-label>
          <input
            id="currentPassword"
            matInput
            formControlName="currentPassword"
            class="accent-border-bottom-color-focus"
            type="password"
            autocomplete="current-password"
          />
          <button mat-icon-button tabindex="-1" type="button" matSuffix (click)="showHidePassword(currentPasswordEl)">
            <mat-icon [svgIcon]="currentPasswordEl.type !== 'password' ? 'eye' : 'eye-off'"></mat-icon>
          </button>
          <mat-error *ngIf="passwordForm.get('currentPassword')!.hasError('required')">
            Please enter your current password
          </mat-error>
          <mat-error *ngIf="passwordForm.get('currentPassword')!.hasError('invalidPassword')">
            Your current password is incorrect
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </ng-container>
  <ng-container ih-footer>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-button form="passwordForm" type="submit">Confirm</button>
  </ng-container>
</ih-basic-dialog>
